<template>
    <div class="content-wrapper">
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0">Chat</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item"><a href="#">Home</a></li>
                            <li class="breadcrumb-item active">Dashboard v1</li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
        <section class="content">
            <div class="card direct-chat direct-chat-primary">
                <div class="card-header ui-sortable-handle" style="cursor: move;">
                    <h3 class="card-title">{{ student.name }}</h3>
                </div>
                <div class="card-body">
                    <div class="direct-chat-messages" id="messages">
                        <div class="direct-chat-msg" :class="message.student_is_author ? 'left' : 'right'" v-for="message in messages" :key="message.id">
                            <div class="direct-chat-infos clearfix" v-if="message.student_is_author">
                                <span class="direct-chat-name float-left">{{ student.name }}</span>
                                <span class="direct-chat-timestamp float-right" :class="message.student_is_author ? 'left' : 'right'">{{ new Date(message.created_at).toLocaleString() }}</span>
                            </div>
                            <div class="direct-chat-infos clearfix" v-else>
                                <span class="direct-chat-name float-left">{{ corrector.name }}</span>
                                <span class="direct-chat-timestamp float-right" :class="message.student_is_author ? 'left' : 'right'">{{ new Date(message.created_at).toLocaleString() }}</span>
                            </div>
                            <img class="direct-chat-img" :src="message.student_is_author ? student.avatar : corrector.avatar" alt="message user image">
                            <div class="direct-chat-text">{{ message.message }}</div>
                        </div>
                    </div>
                </div>
                <div class="card-footer">
                    <div class="input-group">
                        <input type="text" placeholder="Digite a mensagem..." class="form-control" v-model="form.message">
                        <span class="input-group-append">
                            <button type="button" class="btn btn-primary" @click="sendMessage()" v-if="form.message.length != 0">Enviar</button>
                        </span>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import { CHAT_INDEX } from '../../constants/api'
import api from '../../services/api'

export default {
    data() {
        return {
            messages: [],
            student: [],
            corrector: [],
            form: {
                message: ''
            }
        }
    },
    created() {

        this.corrector = JSON.parse(localStorage.getItem('corrector'))

        api.get(`${CHAT_INDEX}/${this.$route.params.id}`).then((response) => {
            console.log(response.data)
            this.messages = response.data.messages
            this.student = response.data.student
        })
    },
    methods: {
        sendMessage() {
            // TODO error treatment
            api.post(`${CHAT_INDEX}/${this.$route.params.id}`, this.form).then((response) => {
                this.messages.push(response.data)
            })
        },
    }
}
</script>

<style scoped>
    .item:hover {
        cursor: pointer;
    }
    
    .direct-chat-messages {
        height: calc(100vh - 310px);
    }
</style>
